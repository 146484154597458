'use client';

import { cn } from '@pickleballinc/ui/lib/utils';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { ErrorCharacter1 } from './characters/404-1';
import { ErrorCharacter2 } from './characters/404-2';
import { ErrorCharacter3 } from './characters/404-3';
import { ErrorCharacter4 } from './characters/404-4';

interface ErrorProps {
	title: string;
	description?: string;
	/**
	 * The children elements, this will be displayed below the title and description. Usually buttons by design.
	 * The children are wrapped with a flex row container (column on mobile) with a gap of 2, so be sure to
	 * have the buttons full width on mobile to make them bigger, or don't, up to you.
	 * @example
	 * ```tsx
	 * import { Button } from '@pickleball/ui/button';
	 *
	 * <ErrorPage title="Page not found">
	 *  <Button>Go back</Button>
	 * </ErrorPage>
	 */
	children?: ReactNode;
	/**
	 * The theme color in which the character will be colored in. Defaults to pickleball purple.
	 * @example
	 * ```tsx
	 * <ErrorPage title="Page not found" themeColor="#ff0000" />
	 */
	themeColor?: string;
	/**
	 * The status code of the error. Defaults to 404.
	 * This is used to determine which characters to display.
	 * Currently has no effect.
	 */
	code?: 404;
	titleClassName?: string;
	descriptionClassName?: string;
}

/**
 * For an example of usage, see [not-found.tsx](https://github.com/pickleballinc/pickleball/blob/dev/apps/pickleball/src/app/not-found.tsx) on the Pickleball app.)
 * Some of the props contain further documentation, so hover over them to see it.
 */
export function ErrorPage(props: ErrorProps) {
	const ErrorCharacter = useMemo(() => {
		const characters = [ErrorCharacter1, ErrorCharacter2, ErrorCharacter3, ErrorCharacter4];
		return characters[Math.floor(Math.random() * characters.length)] ?? ErrorCharacter1;
	}, []);

	return (
		<div className="flex size-full min-h-[600px] flex-col items-center justify-center gap-8 overflow-hidden">
			<ErrorCharacter themeColor={props.themeColor} className="w-full max-w-[80vw]" />
			<div className="flex flex-col items-center gap-2">
				<h2 className={cn('text-center text-[30px] font-semibold', props.titleClassName)}>{props.title}</h2>
				<p className={cn('text-center text-[20px] text-gray-600', props.descriptionClassName)}>{props.description}</p>
			</div>
			<div className="flex flex-col gap-2 md:flex-row">{props.children}</div>
		</div>
	);
}
