'use client';

import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/ui/components/ui/button';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

export function BackButton() {
	const router = useRouter();
	const [canGoBack, setCanGoBack] = useState(true);

	useEffect(() => {
		setCanGoBack(Boolean(window.history?.length && window.history.length > 1));
	}, []);

	if (!canGoBack) return null;

	return (
		<Button
			onClick={() => router.back()}
			variant="outline"
			className="shadow-none flex w-full items-center gap-2 border-gray-300 bg-white drop-shadow-none md:w-32"
		>
			<FontAwesomeIcon icon={faArrowLeft} />
			Back
		</Button>
	);
}
